import { render, staticRenderFns } from "./VideoCV.vue?vue&type=template&id=bbf8dd54&scoped=true&"
import script from "./VideoCV.vue?vue&type=script&lang=js&"
export * from "./VideoCV.vue?vue&type=script&lang=js&"
import style0 from "./VideoCV.vue?vue&type=style&index=0&id=bbf8dd54&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbf8dd54",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CSwitch: require('@chakra-ui/vue').CSwitch, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CGrid: require('@chakra-ui/vue').CGrid, CFormControl: require('@chakra-ui/vue').CFormControl, CTextarea: require('@chakra-ui/vue').CTextarea, CDivider: require('@chakra-ui/vue').CDivider, CImage: require('@chakra-ui/vue').CImage})
