<template>
  <!-- <CBox bg="bgMain" height="full">
    <CBox ml="60px" mr="60px" pt="20px" pl="10px" pr="10px" pb="5px"> -->
      <!-- <CHeading color="fontMain" size="2xl">Dashboard Premium</CHeading>
      <CDivider borderColor="dividerColor" /> -->
  <CBox my="20px">
      <CGrid templateColumns="7fr 3fr" :gap="6">
        <CBox w="100%" bg="white" border="1px" borderColor="borderBox" h="min-content">
          <!-- <CBox d="flex" mx="32px" my="15px">
            <BreadcrumbUpload />
          </CBox> -->
          <!-- <CDivider borderColor="borderBox" border="1.5px" /> -->
          <!-- <CBox mx="32px" my="20px"> -->
            <CBox v-if="!user.emp_id">
              <CBox h="20px" />
              <Loading />
            </CBox>
            <CBox v-if="user.emp_id" mx="32px" my="20px">
              <CBox d="flex" justifyContent="space-between" my="10px">
                <CText fontWeight="bold" color="fontMain">Upload Video Biografi</CText>
                <CBox d="flex">
                  <CText fontWeight="bold" color="fontMain" fontSize="14px">Tampilkan Video Biografi</CText>
                  <CSwitch ml="10px" color="blue" :isChecked="form.isShow" @click="form.isShow = !form.isShow" />
                </CBox>
              </CBox>
              <CBox maxH="calc(100vh - 310px)" overflowY="scroll">
                <CFormControl>
                  <CGrid templateColumns="1fr 3fr" :gap="6" w="100%">
                    <CFormLabel color="fontMain">URL Video</CFormLabel>
                    <CInput type="text" placeholder="Tuliskan URL Video Biografi" v-model="form.video_url" :disabled="!form.isShow" :aria-disabled="!form.isShow" @focus="focusForm = 'url'" @blur="focusForm = 'description'"></CInput>
                  </CGrid>
                </CFormControl>
                <CFormControl mt="10px">
                  <CGrid templateColumns="1fr 3fr" :gap="6" w="100%">
                    <CFormLabel color="fontMain">Deskripsi Tambahan</CFormLabel>
                    <CTextarea h="200px" placeholder="Tuliskan deskripsi video disini" v-model="form.video_description" :disabled="!form.isShow" :aria-disabled="!form.isShow" @focus="focusForm = 'description'" :value="form.video_description"></CTextarea>
                  </CGrid>
                </CFormControl>
                <CGrid templateColumns="1fr 3fr" :gap="6" w="100%" mt="20px">
                  <CBox></CBox>
                  <CBox w="100%" bg="videoNote" p="10px">
                    <CText fontSize="14px" fontFamily="Montserrat" fontWeight="600" color="fontInformation">Kamu dapat me-nonactive kan bagian video biografi ini dengan opsi on/off diatas.<br />Kamu juga dapat mengisi bagian video biografi ini lain kali.</CText>
                  </CBox>
                </CGrid>
              </CBox>
            </CBox>
            <CDivider v-if="user.emp_id" borderColor="borderBox" border="1.5px" />
            <CBox v-if="user.emp_id" align="right" m="20px">
              <ButtonBlue :disabled="disabledButton" label="Lanjutkan" @click.native="onSubmit()" />
            </CBox>
          <!-- </CBox> -->
        </CBox>
        <CBox>
          <CBox v-if="focusForm == 'url'" mx="10px" w="100%" bg="white" border="1px" borderColor="borderBox" style="position: relative;" >
            <CBox class="coba" borderLeft="1px" borderBottom="1px" borderColor="borderBox" style="position: absolute; top: 20px;" h="10px" />
            <CBox my="20px" mx="15px">
              <CText fontWeight="bold" color="fontInformation">Video Biografi</CText>
              <CText color="fontInformation" fontSize="14px">
                Kamu dapat men-publish video biografi yang sudah kamu upload di Youtube untuk meningkatkan value tentang diri kamu kepada pengunjung Web CV kamu.
              </CText>
              <CImage :src="require('@/assets/img/video-url-ilustration.png')" />
            </CBox>
          </CBox>
          <CBox v-else mx="10px" w="100%" bg="white" border="1px" borderColor="borderBox" style="position: relative;">
            <CBox class="coba" borderLeft="1px" borderBottom="1px" borderColor="borderBox" style="position: absolute; top: 20px;" h="10px" />
            <CBox my="20px" mx="15px">
              <CText fontWeight="bold" color="fontInformation">Deskripsi Tambahan</CText>
              <CText color="fontInformation" fontSize="14px">
                Kamu dapat menuliskan deskripsi tambahan tentang kamu disini. Sebagai contoh kamu bisa menuliskan alasan agar pengunjung Web CV kamu harus menjalin kerjasama denganmu.
              </CText>
              <CImage :src="require('@/assets/img/video-description-ilustration.png')" />
            </CBox>
          </CBox>
        </CBox>
      </CGrid>
      <!-- <WizardFooter @onSubmit="onSubmit()" :disabledButton="disabledButton" /> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
import { GET_USER, UPDATE_VIDEO } from "@/store/auth.module"
import ButtonBlue from "@/components/button/ButtonBlue.vue"
// import ButtonSecondary2 from "@/components/button/ButtonSecondary2.vue"
// import BreadcrumbUpload from "@/components/breadcrumb/BreadcrumbUpload.vue"
import Swal from "sweetalert2";
import Loading from "@/components/Loading.vue";
// import WizardFooter from "@/components/footer/WizardFooter.vue";

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

export default {
  metaInfo: {
    title: "Video CV",
    titleTemplate: "%s - Gethired.id"
  },
  components: {
    ButtonBlue,
    Loading,
    // WizardFooter
    // ButtonSecondary2,
    // BreadcrumbUpload
  },
  data() {
    return {
      user: {},
      form: {
        isShow: true,
        video_url: "",
        video_description: ""
      },
      focusForm: "url",
      disabledButton: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      setTimeout(async () => {
        await this.$store.dispatch(GET_USER)
        const user = this.$store.getters.currentUser;
        this.user = user;
        this.form.isShow = user.video.is_visible ? user.video.is_visible : false;
        this.form.video_url = user.video.video_url ? user.video.video_url : "";
        this.form.video_description = user.video.video_description ? user.video.video_description: "";
      }, 100);
    },
    onSubmit() {
      const videoId = getId(this.form.video_url);
      if (this.form.isShow && !videoId) return;
      const video = "https://www.youtube.com/embed/"+videoId;

      this.disabledButton = true;

      this.$store
        .dispatch(UPDATE_VIDEO, {
          id: this.user.emp_id,
          data: {
            is_visible: this.form.isShow,
            video_url: video,
            video_description: this.form.video_description,
          }
        })
        .then(() => {
          this.disabledButton = false;
          this.$router.push({ name: "Config Services" })
        })
        .catch((err) => {
          this.disabledButton = false;
          let errData = err.message;
          if (err.response && err.response.data && err.response.data.message)
            errData = err.response.data.message;
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
    },
  }
}
</script>

<style scoped>
.coba {
  content: "";
  left: calc(-0.5rem);
  transform: rotate(45deg);
  background-color: rgb(255, 255, 255);
  display: block;
  width: 1rem;
  height: 1rem;
}
</style>
